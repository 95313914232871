import { Link, useMatches } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useTranslation } from 'react-i18next'
import {
	type NavigationCategoriesStoryblok,
	type NavigationBlokStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import { Button } from '../ui/button'
import PlentyBodyLink from '../ui/plenty-body-link'

interface data {
	navigation: NavigationCategoriesStoryblok[]
}

const NavigationBlok = ({ blok }: { blok: NavigationBlokStoryblok }) => {
	const matches = useMatches()
	const { t } = useTranslation('navbar')
	const navItems = [
		{ link: '/women-landing', name: t('women_header') },
		{ link: '/men-landing', name: t('men_header') },
		{ link: '/kids-landing', name: t('kids_header') },
	]
	const navigationData = matches.find(
		match => match.id === 'routes/_public+/_layout',
	)
	if (!navigationData) return null

	const { navigation } = navigationData.data as data

	return (
		<div
			className="flex w-full flex-col items-center space-y-4"
			{...storyblokEditable(blok)}
		>
			<PlentyBody size="md">{blok.title}</PlentyBody>
			<div className="flex w-full flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-6 md:space-y-0">
				{navItems.map(category => {
					return (
						<div key={category.name} className="w-full space-y-4 md:w-fit">
							<Link to={category.link}>
								<Button variant="secondary" size="secondary" fullWidth={true}>
									{category.name}
								</Button>
							</Link>
							<div className="flex flex-col space-y-2">
								{navigation
									.find(it => it.title === category.name)
									?.categories?.slice(0, 3)
									.map(subCategory => {
										return (
											<div key={subCategory._uid}>
												<PlentyBodyLink
													link={subCategory.link?.url}
													fullWidth
													barClassName="bg-beige-70"
												>
													{subCategory.title}
												</PlentyBodyLink>
											</div>
										)
									})}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default NavigationBlok
