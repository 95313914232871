import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { AnimatePresence, type Variants, motion } from 'framer-motion'
import React from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { type TabsStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import { Alert } from '../ui/alert'
import { Icon } from '../ui/icon'

interface TabsBlokProps {
	blok: TabsStoryblok
}

const tabContentVariants: Variants = {
	initial: {
		y: 10,
		opacity: 0,
	},
	enter: {
		y: 0,
		opacity: 1,
	},
	exit: {
		y: -10,
		opacity: 0,
	},
}

const TabsBlok = ({ blok }: TabsBlokProps) => {
	const [activeTab, setActiveTab] = React.useState<string | null>(
		blok?.tabs ? blok?.tabs[0]._uid : null,
	)
	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	const handleTabClick = (tabId: string) => {
		setActiveTab(prevTab => (prevTab === tabId ? null : tabId))
	}

	if (!blok.tabs) return null

	return (
		<ClientOnly fallback={<></>}>
			{() => {
				if (!blok.tabs) return <></>
				return (
					<div {...storyblokEditable(blok)} className="flex w-full flex-col">
						<div className="flex flex-col">
							<div className="mb-4 flex w-full flex-col justify-between md:flex-col md:gap-2 ">
								<div className="flex h-fit w-full flex-col justify-between gap-4 md:w-full md:flex-row md:text-left">
									{blok.tabs.map(tab => (
										<div key={tab._uid} className="w-full space-y-2">
											<div
												className="'group flex w-full cursor-pointer items-center justify-between whitespace-nowrap rounded-md border border-beige-50 px-4 py-4 ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-black-90 data-[state=active]:text-white"
												onClick={() => handleTabClick(tab._uid)}
												data-state={
													activeTab === tab._uid ? 'active' : 'inactive'
												}
											>
												<PlentyBody>{tab.title}</PlentyBody>
												<Icon
													name="chevron-down"
													size="lg"
													className={`transition-all ${
														activeTab === tab._uid ? 'rotate-180' : ''
													}`}
												/>
											</div>
											{activeTab === tab._uid && !isDesktop && (
												<AnimatePresence mode="wait">
													<motion.div
														variants={tabContentVariants}
														initial="initial"
														animate="enter"
														exit="exit"
														transition={{
															duration: 0.3,
														}}
													>
														<Alert className="relative">
															<>
																<Icon
																	name="close"
																	className="absolute right-4 top-4"
																	size="lg"
																	onClick={() => setActiveTab(null)}
																/>
																{tab.content.map((blok: any) => (
																	<StoryblokComponent
																		key={blok._uid}
																		blok={blok}
																	/>
																))}
															</>
														</Alert>
													</motion.div>
												</AnimatePresence>
											)}
										</div>
									))}
								</div>
								<div>
									{isDesktop &&
										blok.tabs.map(tab => {
											return activeTab === tab._uid ? (
												<TabContent
													tab={tab}
													setActiveTab={activeTab}
													key={'something'}
												/>
											) : null
										})}
								</div>
							</div>
						</div>
					</div>
				)
			}}
		</ClientOnly>
	)
}

const TabContent = ({ tab, setActiveTab }: { tab: any; setActiveTab: any }) => {
	return (
		<AnimatePresence mode="wait">
			<motion.div
				variants={tabContentVariants}
				initial="initial"
				animate="enter"
				exit="exit"
				transition={{
					duration: 0.3,
				}}
			>
				<Alert className="relative">
					<>
						<Icon
							name="close"
							className="absolute right-4 top-4 cursor-pointer"
							size="lg"
							onClick={() => setActiveTab(null)}
						/>
						{tab.content.map((blok: any) => (
							<StoryblokComponent key={blok._uid} blok={blok} />
						))}
					</>
				</Alert>
			</motion.div>
		</AnimatePresence>
	)
}

export default TabsBlok
