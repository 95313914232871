import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type LgSpacerStoryblok } from '#types/component-types-sb'

const lgSpacer = ({ blok }: LgSpacerStoryblok) => {
	const isOn = blok.spacingSm !== undefined ? blok.spacingSm : true

	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="lg"
			isOn={isOn}
		/>
	)
}

export default lgSpacer
