import { StoryblokComponent, type SbBlokData } from '@storyblok/react'

import { type StoreHeaderStoryblok } from '#types/component-types-sb'

const StoreHeader = ({ blok }: StoreHeaderStoryblok) => {
	const leftSideBlocks = Array.isArray(blok.leftSide) ? blok.leftSide : []
	const rightSideBlocks = Array.isArray(blok.rightSide) ? blok.rightSide : []
	const { className } = blok
	// This is for the Amplitude tracking if we change the storePage this has to be moved else where.

	return (
		<div
			className={`mx-auto flex w-full flex-col justify-between gap-6 pb-14 pt-6 md:flex-row md:gap-0 md:pl-[var(--plenty-padding)] md:pt-0 ${
				className || ''
			}`}
		>
			<div className="flex w-full flex-1 flex-col px-4 md:block md:px-0 md:pt-[72px]">
				{leftSideBlocks.map((leftBlock: SbBlokData) => (
					<StoryblokComponent key={leftBlock._uid} blok={leftBlock} />
				))}
			</div>
			<div className="flex-1 flex-grow">
				{rightSideBlocks.map((rightBlock: SbBlokData) => (
					<StoryblokComponent key={rightBlock._uid} blok={rightBlock} />
				))}
			</div>
		</div>
	)
}
export default StoreHeader
