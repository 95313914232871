import { StoryblokComponent, type SbBlokData } from '@storyblok/react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { type CardListStoryblok } from '#types/component-types-sb'
import { Carousel, CarouselContent, CarouselItem } from '../ui/carousel'

const CardList = ({ blok }: CardListStoryblok) => {
	return (
		<Carousel
			className="relative w-full md:mx-[-0rem]"
			plugins={[WheelGesturesPlugin()]}
			opts={{ dragFree: true }}
		>
			<CarouselContent key={blok._uid} className="gap-4">
				{blok.cards.map((blokCard: SbBlokData) => (
					<CarouselItem key={blokCard._uid} className="w-full max-w-[192px]">
						<StoryblokComponent key={blokCard._uid} blok={blokCard} />
					</CarouselItem>
				))}
			</CarouselContent>
		</Carousel>
	)
}

export default CardList
