import { type SbBlokData, StoryblokComponent } from '@storyblok/react'
import { useState } from 'react'
import { type FaqGridStoryblok } from '#types/component-types-sb'
import ContactModal from '../contact-modal'

const FaqGrid = ({ blok }: FaqGridStoryblok) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const toggleModal = () => setIsModalOpen(!isModalOpen)
	return (
		<>
			<div className="flex grid-cols-3 flex-col md:grid md:space-x-28">
				<div className="order-2 space-y-4 md:order-1">
					{blok.columnOne.map((blokColumn: SbBlokData) => (
						<div
							key={blokColumn._uid}
							className="flex justify-center text-center md:justify-start md:text-start"
						>
							<StoryblokComponent blok={blokColumn} />
						</div>
					))}
					<ContactModal isOpen={isModalOpen} onClose={toggleModal} />
				</div>
				<div className="order-1 col-span-2 md:order-2">
					{blok.columnTwo.map((blokColumn: SbBlokData) => (
						<div key={blokColumn._uid} className="justify-start text-left">
							<StoryblokComponent blok={blokColumn} />
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default FaqGrid
