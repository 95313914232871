import { storyblokEditable } from '@storyblok/react'
import { render } from 'storyblok-rich-text-react-renderer'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '#app/components/ui/accordion'
import { type AccordionStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'


const AccordionBlok = ({ blok }: AccordionStoryblok) => {
	return (
		<div className="flex" {...storyblokEditable(blok)}>
			<Accordion type="multiple" className="w-full">
				<AccordionItem className=" border-b pb-2" value="color">
					<AccordionTrigger className="items-left flex text-left">
						<PlentyBody size="md" className="items-left">
							{blok.accordion_label}
						</PlentyBody>
					</AccordionTrigger>
					<AccordionContent className="flex-w-full flex flex-col py-2 text-start">
						{render(blok.accordion_body)}
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	)
}

export default AccordionBlok
