import type RadixDropdownMenu from '@radix-ui/react-dropdown-menu'

import * as Tooltip from '@radix-ui/react-tooltip'
import { useCaptionOptions, useMediaPlayer } from '@vidstack/react'
import { SubtitlesIcon } from 'lucide-react'
import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import { buttonClass, tooltipClass } from './buttons'
export interface MenuProps {
	side?: RadixDropdownMenu.DropdownMenuContentProps['side']
	align?: RadixDropdownMenu.DropdownMenuContentProps['align']
	offset?: RadixDropdownMenu.DropdownMenuContentProps['sideOffset']
	tooltipSide?: Tooltip.TooltipContentProps['side']
	tooltipAlign?: Tooltip.TooltipContentProps['align']
	tooltipOffset?: number
}

// We can reuse this class for other menus.
const menuClass =
	'animate-out fade-out z-[9999] slide-in-from-bottom-4 data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:slide-out-to-bottom-2 flex max-h-[400px] min-w-[260px] flex-col rounded-md border border-white/10 bg-black/95 p-2.5 font-sans text-[16px] font-medium outline-none backdrop-blur-sm duration-300'

export function Captions({
	side = 'top',
	align = 'end',
	offset = 0,
	tooltipSide = 'top',
	tooltipAlign = 'center',
	tooltipOffset = 0,
}: MenuProps) {
	const player = useMediaPlayer(),
		options = useCaptionOptions(),
		hint = options.selectedTrack?.label ?? 'Off'
	return (
		<DropdownMenu>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<DropdownMenuTrigger
						aria-label="Settings"
						className={buttonClass}
						disabled={options.disabled}
					>
						<SubtitlesIcon className="h-7 w-7" />
					</DropdownMenuTrigger>
				</Tooltip.Trigger>
				<Tooltip.Content
					className={tooltipClass}
					side={tooltipSide}
					align={tooltipAlign}
					sideOffset={tooltipOffset}
				>
					Captions
				</Tooltip.Content>
			</Tooltip.Root>
			<DropdownMenuContent
				className={cn(menuClass, 'text-white')}
				side={side}
				align={align}
				sideOffset={offset}
				collisionBoundary={player?.el}
			>
				<DropdownMenuLabel className="mb-2 flex w-full items-center px-1.5 text-[16px] font-medium">
					<SubtitlesIcon className="mr-1.5 h-5 w-5 translate-y-px" />
					Captions
					<span className="ml-auto text-sm text-white/50">{hint}</span>
				</DropdownMenuLabel>
				<DropdownMenuRadioGroup
					aria-label="Captions"
					className="flex w-full flex-col"
					value={options.selectedValue}
				>
					{options.map(({ label, value, select }) => (
						<Radio value={value} onSelect={select} key={value}>
							{label}
						</Radio>
					))}
				</DropdownMenuRadioGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

function Radio({ children, ...props }: any) {
	return (
		<DropdownMenuRadioItem
			className="ring-media-focus group relative flex w-full cursor-pointer select-none items-center justify-start rounded-sm p-2.5 text-sm outline-none hover:text-white data-[focus]:ring-[3px] hocus:bg-white/10"
			{...props}
		>
			<Icon
				name="check-circle"
				size="md"
				className="text-media-brand hidden  text-white group-data-[state=checked]:block "
			/>
			<span className="ml-2 hover:text-white">{children}</span>
		</DropdownMenuRadioItem>
	)
}
