import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { type VideoStoryblok } from '#types/component-types-sb'

const VideoBlok = ({ blok }: VideoStoryblok) => {
	return (
		<div {...storyblokEditable(blok)} key={blok._uid}>
			<video
				src={blok.video?.filename}
				className="inset-0 max-h-[700px] w-full rounded-lg object-cover"
				autoPlay
				muted
				loop
				playsInline
			/>
		</div>
	)
}

export default VideoBlok
