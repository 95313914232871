import { useEffect, useRef } from 'react'

const UseMetaPixel = () => {
	const injected = useRef(false)

	useEffect(() => {
		if (!injected.current 
			// && process.env.NODE_ENV === 'production' comment in again if it works. METAMAN

		) {
			const script = document.createElement('script')
			script.textContent = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '198679609890064');
      fbq('track', 'PageView');`
			document.head.appendChild(script)

			const noscript = document.createElement('noscript')
			const img = document.createElement('img')
			img.src =
				'https://www.facebook.com/tr?id=198679609890064&ev=PageView&noscript=1'
			img.height = 1
			img.width = 1
			img.style.display = 'none'
			noscript.appendChild(img)
			document.body.appendChild(noscript)

			injected.current = true

			return () => {
				document.head.removeChild(script)
				document.body.removeChild(noscript)
			}
		}
	}, [])

	return null
}

export default UseMetaPixel
