import { StoryblokComponent } from '@storyblok/react'
import { useState } from 'react'
import {
	type StepCardStoryblok,
	type HowItWorksStoryblok,
} from '#types/component-types-sb'
import PlentyHeading from '../typography/plenty-heading'
import { Card, CardContent } from '../ui/card'
import { Icon } from '../ui/icon'

const HowItWorks = ({ blok }: { blok: HowItWorksStoryblok }) => {
	const [active, setActive] = useState(true)
	if (!blok.clickAndCollectCards || !blok.deliveryCards) return
	return (
		<div className="m-auto flex w-full flex-col items-center space-y-4 rounded-2xl bg-beige-50-opacity p-4 md:max-w-[1100px] md:space-y-6 md:p-8">
			<div className="flex flex-col space-x-1 md:flex-row">
				<PlentyHeading as="h7">{blok.heading} </PlentyHeading>
				<div
					className="flex cursor-pointer flex-row justify-center space-x-2"
					onClick={() => setActive(!active)}
				>
					<PlentyHeading as="h7" className="text-primary-500 font-bold">
						{active ? blok.clickAndCollectTitle : blok.deliveryTitle}
					</PlentyHeading>
					<Icon
						name="chevron-down"
						size="lg"
						className="transition-all group-data-[state=active]:rotate-180"
					/>
				</div>
			</div>
			<div
				className="grid grid-cols-1 gap-4 md:grid-cols-2"
				key={active ? 'cc' : 'delivery'}
			>
				{active
					? blok.clickAndCollectCards.map(card => {
							return <StepCard key={card._uid} blok={card} />
					  })
					: blok.deliveryCards.map(card => {
							return <StepCard key={card._uid} blok={card} />
					  })}
			</div>
		</div>
	)
}

export default HowItWorks

const StepCard = ({ blok }: { blok: StepCardStoryblok }) => {
	if (!blok.content) return

	return (
		<div className="flex flex-col items-center justify-start ">
			<img
				alt="as"
				src={blok.image.filename}
				className="max-h-[300px] rounded-xl object-cover object-top"
			/>
			<Card className="relative -top-[24px] w-[90%] border-beige-50 md:w-[85%]">
				<CardContent>
					{blok.content.map((ctn, idx: number) => (
						<StoryblokComponent blok={ctn} key={idx} />
					))}
				</CardContent>
			</Card>
		</div>
	)
}
