import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { type VideoBannerStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import { SlidingText } from '../common/sliding-text'

const VideoBanner = ({ blok }: VideoBannerStoryblok) => {
	return (
		<div className="w-full" {...storyblokEditable(blok)}>
			<SlidingText
				text={blok.slidingText}
				direction="right"
				key={`right-${blok.slidingText}`}
			/>
			<div className="relative flex h-[420px] flex-col items-center justify-center md:h-[390px]">
				<video
					src={blok.video?.filename}
					className="absolute inset-0 -z-20 max-h-[420px] w-full object-cover grayscale md:max-h-[390px]"
					autoPlay
					muted
					loop
					playsInline
				/>
				<BlackBackgroundColorForImages className="opacity-40" />
				<div className="z-20">
					<StoryblokComponent blok={blok.alignContainer[0]} />
				</div>
			</div>
			<SlidingText
				text={blok.slidingText}
				direction="left"
				key={`left-${blok.slidingText}`}
			/>
		</div>
	)
}

export default VideoBanner
