import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useTranslation } from 'react-i18next'
import { type StoresStoreCardStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const StoresStoreCard = ({ blok }: StoresStoreCardStoryblok) => {
	const { t } = useTranslation('stores_page')

	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex w-full flex-col items-center"
		>
			<Link to={blok.linkToStorePage} className="z-10 w-full">
				<div className="flex w-full flex-col items-start justify-start rounded-lg bg-beige-50">
					<div className="flex w-full flex-col gap-[4px] p-4">
						<PlentyBody size="md">{blok.city}</PlentyBody>
						<PlentyHeading as="h7">{blok.storeName}</PlentyHeading>
						<PlentyBody size="md">{blok.storeAddress}</PlentyBody>
						<PlentyBody size="md" className="underline">
							{t('view-store-info')}
						</PlentyBody>
					</div>
				</div>
			</Link>
		</div>

		// This code has to come back when we have the image of the stores
		// <div
		// 	{...storyblokEditable(blok)}
		// 	key={blok._uid}
		// 	className="flex flex-col items-center"
		// >
		// 	<Link to={blok.linkToStorePage} className="z-10">
		// 		<div className="group relative flex w-full overflow-hidden rounded-t-lg ">
		// 			{' '}
		// 			<img
		// 				src={blok.storeImage?.filename}
		// 				alt="brand"
		// 				className="h-auto w-full rounded-t-lg object-contain"
		// 			/>
		// 		</div>
		// 		<div className="flex w-full flex-col items-start justify-start rounded-b-lg bg-beige-50">
		// 			<div className="flex flex-col gap-[4px] p-4">
		// 				<PlentyBody size="md" >{blok.city}</PlentyBody>
		// 				<PlentyHeading as="h7" >{blok.storeName}</PlentyHeading>
		// 				<PlentyBody size="md" >{blok.storeAddress}</PlentyBody>

		// 				<PlentyBody as="m" body={t('view-store-info')} className="underline" />
		// 			</div>
		// 		</div>
		// 	</Link>
		// </div>
	)
}

export default StoresStoreCard
