import { Link } from '@remix-run/react'
import { type LinkedTextStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'

const LinkedText = ({ blok }: LinkedTextStoryblok) => {
	return (
		<div className="flex flex-col md:flex-row md:gap-1">
			{blok.bodyText && (
				<PlentyBody
					key={blok.bodyText._uid}
					size={blok.as}
					className={`text-${blok.textColor} font-${blok.weight}`}
				>
					{blok.bodyText}
				</PlentyBody>
			)}
			<Link to={blok.link.url}>
				<PlentyBody
					key={blok.linkText._uid}
					size={blok.as}
					className={`text-${blok.textColor} font-${blok.weight} underline`}
				>
					{blok.linkText}
				</PlentyBody>
			</Link>
		</div>
	)
}

export default LinkedText
