import { storyblokEditable } from '@storyblok/react'
import { type BodyBackgroundTextStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
const BodyBackgroundTextBlok = ({ blok }: BodyBackgroundTextStoryblok) => {
	const textBackgroundStyles = blok.backgroundTextColor
		? {
				backgroundColor: `${blok.backgroundTextColor}`,
				borderRadius: '0.5rem',
				padding: '0.25rem',
		  }
		: {}
	const content = (
		<div {...storyblokEditable(blok)} key={blok._uid} className="inline-block">
			<PlentyBody
				size={blok.as}
				className={`text-${blok.textColor} font-${blok.weight}`}
			>
				<span style={textBackgroundStyles}>{blok.body}</span>
			</PlentyBody>
		</div>
	)
	return content
}
export default BodyBackgroundTextBlok
