import { renderRichText } from '@storyblok/react'
import { type RichtextStoryblok } from '#types/component-types-sb'
import renderEmojis from './emoji-renderer'

const RichText = ({ blok }: RichtextStoryblok) => {
	const richTextHTML = renderRichText(blok?.richText)

	return (
		<div className="flex w-full flex-col items-center justify-center text-center md:flex-row">
			{renderEmojis(richTextHTML, {})}
		</div>
	)
}

export default RichText
