import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { type PageStoryblok } from '#types/component-types-sb'

const Page = ({ blok }: PageStoryblok) => {
	const bgImageClass = blok.triplePillShown
		? "bg-[url('/img/triple-pill-blob.svg')] bg-no-repeat "
		: ''

	return (
		<main
			{...storyblokEditable(blok)}
			key={blok._uid}
			className={` ${bgImageClass} `}
		>
			{blok.body.map((nestedBlok: SbBlokData) => (
				<StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
			))}
		</main>
	)
}

export default Page
