import { Link, useLoaderData } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import * as React from 'react'

import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel'
import { type loader } from '#app/routes/_public+/$'
import { type StoresSliderStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'
import { Button } from '../ui/button'
import { Card, CardContent } from '../ui/card'
import PlentyBodyLink from '../ui/plenty-body-link'

interface Store {
	name: string
	address: string
	image: string
	link: string
}
const CardSlider = ({ blok }: StoresSliderStoryblok) => {
	const data = useLoaderData<typeof loader>()

	const stores = data.storesInformation.map((store: any) => {
		return {
			name: store.content.store_name,
			address: store.content.store_address,
			image: store.content.store_image.filename,
			link: `/stores/${store.slug}`,
		}
	})

	return (
		<div
			className="flex flex-col space-y-8 md:space-y-0"
			{...storyblokEditable(blok)}
		>
			<div className="flex w-full flex-col space-y-2 md:hidden md:max-w-[284px]">
				<CardText
					title={blok.title}
					subtitle={blok.subtitle}
					buttonLink={blok.buttonLink}
					buttonText={blok.buttonText}
				/>
			</div>
			<div className="flex flex-col overflow-x-auto scrollbar-hide">
				<Carousel
					plugins={[WheelGesturesPlugin()]}
					opts={{ dragFree: true }}
					className="w-full"
				>
					<CarouselContent>
						<CarouselItem className="hidden w-full max-w-[284px] flex-col space-y-2 md:flex">
							<CardText
								title={blok.title}
								subtitle={blok.subtitle}
								buttonLink={blok.buttonLink}
								buttonText={blok.buttonText}
							/>
						</CarouselItem>
						{stores.map((store: Store, index: number) => (
							<CardStore store={store} key={index} blok={blok} />
						))}
					</CarouselContent>
				</Carousel>
			</div>
		</div>
	)
}

const CardStore = ({
	store,
	blok,
}: {
	store: Store
	blok: StoresSliderStoryblok
}) => {
	return (
		<CarouselItem className="w-full max-w-[284px]">
			<Link to={store.link} className="group">
				<Card className="border-0 p-0 shadow-none">
					<CardContent className="space-y-2 p-0">
						<div className="overflow-hidden rounded-lg">
							<img
								className="h-[222px] w-full  object-cover transition-transform group-hover:scale-105 md:h-[198px]"
								src={
									store.image
										? store.image
										: 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
								}
								alt={store.name}
							/>
						</div>
						<div className="space-y-2">
							<PlentyBody size="md">{store.name}</PlentyBody>
							<div>
								<PlentyBody size="sm">{store.address}</PlentyBody>
								<PlentyBodyLink> {blok.storeButtonText} </PlentyBodyLink>
							</div>
						</div>
					</CardContent>
				</Card>
			</Link>
		</CarouselItem>
	)
}

const CardText = ({
	title,
	subtitle,
	buttonText,
	buttonLink,
}: {
	title: string
	subtitle: string
	buttonText: string
	buttonLink: string
}) => {
	return (
		<>
			<PlentyHeading as="h7">{title}</PlentyHeading>
			<PlentyBody>{subtitle}</PlentyBody>
			{buttonText && (
				<Link to={buttonLink}>
					<Button variant="secondary" size="secondary" fullWidth={false}>
						{buttonText}
					</Button>
				</Link>
			)}
		</>
	)
}
export default CardSlider
