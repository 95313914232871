import { Link } from '@remix-run/react'
import { useEffect, useRef, useState } from 'react'
import ProductCard from '#app/components/common/product-card/product-card'
import { Button } from '../ui/button'
import { Icon } from '../ui/icon'

type StoryblokProductSliderProps = {
	blok: {
		category: string | string[]
		sliderSize: number
		store?: string[]
	}
}

interface PreviewVariant {
	key: string
}

interface CustomProductData {
	name: { en: string }
	styleNumber: string
	previewVariant: PreviewVariant
	styleVariants: Array<any> // Use a more specific type if you know the structure of style variants
}

export default function StoryblokProductSlider({
	blok,
}: StoryblokProductSliderProps) {
	const [products, setProducts] = useState<CustomProductData[] | undefined>(
		undefined,
	)

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [showLeftArrow, setShowLeftArrow] = useState(false)
	const [showRightArrow, setShowRightArrow] = useState(false)
	let scrollTimeout = useRef<NodeJS.Timeout | null>(null)

	// If performance is a concern we can go to storyblok and change the way we fetch the products pr category. So we just say fetch 5 products for each category.
	useEffect(() => {
		const defaultSliderSize = 15 // Default value
		const { category, store } = blok
		const sliderSize = blok.sliderSize > 0 ? blok.sliderSize : defaultSliderSize
		const storeKey = store ? store.join(',') : ''
		const categories = Array.isArray(category) ? category : [category]

		// Fetch products for each category
		const fetchProductsForCategory = async (cat: string, size: number) =>
			fetch(
				`/resources/productSliderData?category=${cat}&sliderSize=${size}${
					storeKey ? `&store=${storeKey}` : ''
				}`,
			)
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok')
					}
					return response.json()
				})
				.then(data => data.products as CustomProductData[])

		const fetchAllCategories = async () => {
			// Use Promise.all to fetch all categories in parallel for better performance
			const fetchPromises = categories.map(cat =>
				fetchProductsForCategory(cat, sliderSize),
			)
			try {
				const productsFromAllCategories = await Promise.all(fetchPromises)
				// Flatten the array of arrays and then take the first 'sliderSize' products from the result
				const allProducts = productsFromAllCategories.flat()
				setProducts(allProducts.slice(0, sliderSize)) // Adjust if you need to handle the total differently
			} catch (error) {
				console.error('Error fetching products:', error)
			}
		}

		fetchAllCategories()
	}, [blok]) // Dependent on 'blok' to re-run the effect when it changes

	const checkScrollButtons = () => {
		const { current } = scrollContainerRef
		if (current) {
			const maxScrollLeft = current.scrollWidth - current.clientWidth
			setShowLeftArrow(current.scrollLeft > 0)
			setShowRightArrow(current.scrollLeft < maxScrollLeft)
		}
	}

	const handleScroll = () => {
		// Immediately hide arrows on scroll
		setShowLeftArrow(false)
		setShowRightArrow(false)

		// Debounce the checkScrollButtons call
		if (scrollTimeout.current !== null) {
			clearTimeout(scrollTimeout.current)
		}
		scrollTimeout.current = setTimeout(() => {
			checkScrollButtons()
		}, 150) // Adjust delay as needed
	}
	const scroll = (scrollOffset: number) => {
		if (scrollContainerRef.current) {
			// Use scrollTo with smooth behavior for ease-in-ease-out effect
			scrollContainerRef.current.scrollTo({
				left: scrollContainerRef.current.scrollLeft + scrollOffset,
				behavior: 'smooth',
			})
		}
	}

	useEffect(() => {
		const { current } = scrollContainerRef
		if (current) {
			current.addEventListener('scroll', handleScroll, { passive: true })
			checkScrollButtons() // Initial check
		}
		return () => {
			if (current) {
				current.removeEventListener('scroll', handleScroll)
			}
			if (scrollTimeout.current) {
				clearTimeout(scrollTimeout.current)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products])
	if (!products || !Array.isArray(products)) {
		return <div>Error: Products is not an array or undefined</div>
	}

	return (
		<div className="relative mx-[-1rem] md:mx-[-0rem]">
			{showLeftArrow && (
				<Button
					variant={'secondary'}
					onClick={() => scroll(-1000)}
					className={`scroll-button-styles group absolute left-[48px] top-[173px] z-10 hidden rounded-full border border-black bg-white px-4 py-[4px] ${
						showLeftArrow ? 'md:flex' : 'md:hidden'
					}`}
				>
					<Icon
						className="text-body-md text-black group-hover:text-white"
						size="lg"
						name="left-arrow"
					/>
				</Button>
			)}

			<div
				ref={scrollContainerRef}
				className="smooth-scroll flex gap-2 overflow-x-auto px-4 scrollbar-hide md:gap-4 md:px-0"
			>
				{products.map((product, index) => (
					<Link key={index} to={`/product/${product.styleNumber}`}>
						<ProductCard isSlider={true} key={index} product={product} />
					</Link>
				))}
			</div>

			{showRightArrow && (
				<Button
					variant={'secondary'}
					onClick={() => scroll(1000)}
					className={`scroll-button-styles group absolute right-[48px] top-[173px] z-10 hidden rounded-full border border-black bg-white px-4 py-[4px] ${
						showRightArrow ? 'md:flex' : 'md:hidden'
					}`}
				>
					<Icon
						className="text-body-md text-black group-hover:text-white"
						size="lg"
						name="right-arrow"
					/>
				</Button>
			)}
		</div>
	)
}
