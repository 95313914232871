import { storyblokEditable } from '@storyblok/react'
import PlentyHeading from '#app/components/typography/plenty-heading'
import { type HeadingStoryblok } from '#types/component-types-sb'

const HeadingBlok = ({ blok }: HeadingStoryblok) => {
	// hacky as fuck again since we have to change the heading according to a new header for mobile and desktop.
	// 	const headingMobileId = `heading-mobile-${blok._uid}`
	// 	const headingDesktopId = `heading-desktop-${blok._uid}`

	// 	const dynamicStyles = `
	//     #${headingMobileId} {
	//       display: block; /* Show mobile heading by default */
	//     }
	//     #${headingDesktopId} {
	//       display: none; /* Hide desktop heading by default */
	//     }
	//     @media (min-width: 820px) {
	//       #${headingMobileId} {
	//         display: none; /* Hide mobile heading on desktop */
	//       }
	//       #${headingDesktopId} {
	//         display: block; /* Show desktop heading on desktop */
	//       }
	//     }
	//   `

	// Render both mobile and desktop headings
	return (
		<>
			{/* <style>{dynamicStyles}</style> */}
			<div {...storyblokEditable(blok)}>
				<PlentyHeading
					as={blok.fontSizeDesktop}
					id={blok._uid}
					className={`text-${blok.textColor} font-${blok.weight}`}
				>
					{blok.heading}
				</PlentyHeading>
			</div>
		</>
	)
}

export default HeadingBlok
