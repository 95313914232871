import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type XsSpacerStoryblok } from '#types/component-types-sb'

const xsSpacer = ({ blok }: XsSpacerStoryblok) => {
	// Default to true if 'spacing8px' is not defined on the blok
	const isOn = blok.spacingXs !== undefined ? blok.spacingXs : true

	// Pass '5xs' to always use the 8px size option when the spacer is "on"
	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="xs"
			isOn={isOn}
		/>
	)
}

export default xsSpacer
