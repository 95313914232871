export function Spacer({
	size,
	isOn,
}: {
	size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'

	isOn?: boolean
}) {
	const options: Record<typeof size, string> = {
		xs: 'h-2',
		sm: 'h-4',
		md: 'h-6',
		lg: 'h-8',
		xl: 'h-10',
	}
	const className = isOn ? options[size] : 'h-0'
	return <div className={className} />
}
