import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import ContentBlok from '#app/components/storyblok/content-blok'
import { type CategoryCardStoryblok } from '#types/component-types-sb'

const CategoryCard = ({ blok }: CategoryCardStoryblok) => {
	const renderContentBlock = (block: any, index: number) => {
		return (
			<div key={`${block._uid}-${index}`}>
				<ContentBlok blok={block} _uid={''} component={'contentBlok'} />
			</div>
		)
	}

	const contentBlok = Array.isArray(blok.contentBlok) ? blok.contentBlok : []
	const imageUrl = blok.categoryImage?.filename || ''

	return (
		<Link to={blok.redirectTo} className="group">
			<div {...storyblokEditable(blok)} key={blok._uid}>
				<div className="group relative">
					{/* Responsive Image */}

					{/* You can add more <source> elements here for different image versions */}
					<img
						src={imageUrl}
						alt={blok.categoryName || 'Category image'}
						className="w-full rounded-3xl object-cover" // Ensures image is full width and maintains its aspect ratio
					/>

					<div className="absolute inset-0 flex flex-col items-center justify-center rounded-3xl bg-black bg-opacity-20 transition group-hover:bg-opacity-40">
						{contentBlok.map(renderContentBlock)}
					</div>
				</div>
			</div>
		</Link>
	)
}

export default CategoryCard
