import { useEffect, useState } from 'react'
import { ProductCarousel } from '#app/components/product-carousel'
import { type ProductCarouselStoryblok } from '#types/component-types-sb'
import { type ClerkProduct, type PlentyProduct } from '#types/product'
import { LoadingSpinner } from '../ui/spinner'

type StoryblokProductCarouselProps = {
	blok: ProductCarouselStoryblok
}

export default function StoryblokProductCarousel({
	blok,
}: StoryblokProductCarouselProps) {
	const [clerkProducts, setClerkProducts] = useState<
		ClerkProduct[] | undefined
	>(undefined)
	const [commercetoolsProducts, setCommercetoolsProducts] = useState<
		PlentyProduct[] | undefined
	>(undefined)
	const isLoading = !clerkProducts && !commercetoolsProducts

	const { category, store, recommendation, on_sale, brand } = blok
	const sliderSize = blok.sliderSize ? parseInt(blok.sliderSize, 10) : 30
	const storeKey = store ? store.join(',') : ''
	useEffect(() => {
		if (!recommendation) {
			const fetchCommercetoolsProducts = async () => {
				const categoriesForUrl = category
					?.map((cat: string) => `category=${cat}`)
					.join('&')
				try {
					const response = await fetch(
						`/resources/productCarouselData?${categoriesForUrl}&sliderSize=${sliderSize}${
							storeKey ? `&store=${storeKey}` : ''
						}`,
					)
					if (!response.ok) {
						throw new Error(
							`Network response was not ok: ${response.statusText}`,
						)
					}
					const data = await response.json()
					if (!data || !Array.isArray(data.products)) {
						throw new Error('Invalid data format')
					}
					setCommercetoolsProducts(data.products as PlentyProduct[])
				} catch (error) {
					console.error('Failed to fetch products:', error)
					setCommercetoolsProducts([])
				}
			}
			fetchCommercetoolsProducts()
		}
	}, [category, recommendation, sliderSize, storeKey])

	useEffect(() => {
		if (recommendation) {
			const endpoint = recommendation
			const categoryParams = category
				?.map(cat => `categoryKey=${encodeURIComponent(cat)}`)
				.join('&')

			const storeParams =
				store?.map(st => `store=${encodeURIComponent(st)}`).join('&') || ''
			const brandParam = brand ? `&brand=${encodeURIComponent(brand)}` : ''
			const discountParam = on_sale
				? `&on_sale=${encodeURIComponent(on_sale)}`
				: ''

			const url = `/resources/productCarouselDataClerk?endpoint=${encodeURIComponent(
				endpoint,
			)}${categoryParams ? `&${categoryParams}` : ''}${
				storeParams ? `&${storeParams}` : ''
			}${brandParam}${discountParam}`

			const fetchData = async () => {
				try {
					const response = await fetch(url)
					if (!response.ok) {
						throw new Error(
							`Network response was not ok: ${response.statusText}`,
						)
					}
					const data = await response.json()
					setClerkProducts(data)
				} catch (error) {
					console.error('Failed to fetch products:', error)
					setClerkProducts([])
				}
			}
			fetchData()
		} else {
			setClerkProducts([]) // Ensure clerk products are empty if no recommendation
		}
	}, [category, store, brand, recommendation, on_sale])

	return (
		<div>
			{!isLoading ? (
				<div className="flex flex-col space-y-8">
					<ProductCarousel
						clerkProducts={clerkProducts}
						commercetoolsProducts={commercetoolsProducts}
						title={blok.title}
						subtitle={blok.subtitle}
						storeParams={blok.store}
						linkText={blok.linkText}
						linkUrl={blok.linkUrl}
					/>
				</div>
			) : (
				<LoadingSpinner />
			)}
		</div>
	)
}
