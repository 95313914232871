import { storyblokEditable } from '@storyblok/react'
import { type BodyLinkStoryblok } from '#types/component-types-sb'
import BodyLinkComponent from '../ui/plenty-body-link'

interface BodyLinkProps {
	blok: BodyLinkStoryblok
}

const BodyLink = ({ blok }: BodyLinkProps) => {
	return (
		<BodyLinkComponent {...storyblokEditable(blok)} link={blok.bodyLink}>
			{blok.bodyText}
		</BodyLinkComponent>
	)
}

export default BodyLink
