import { useEffect, useRef } from 'react';

const useGoogleTagManagerScript = () => {
  const injected = useRef(false);

  useEffect(() => {
    const gtmId = ENV.GTM_ID;

    if (!injected.current && process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.async = true;
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
      document.head.appendChild(script);

      injected.current = true;

      return () => {
        if (script) {
          document.head.removeChild(script);
        }
      };
    }
  }, []);

  return null;
};

export default useGoogleTagManagerScript;
