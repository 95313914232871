import { storyblokEditable, renderRichText } from '@storyblok/react'
import { type UnorderedListStoryblok } from '#types/component-types-sb'
import PlentyHeading from '../typography/plenty-heading'

const UnorderedList = ({ blok }: UnorderedListStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex flex-col justify-start gap-6 "
		>
			<PlentyHeading as={'h7'}>{blok.titleText}</PlentyHeading>
			<div
				className="list flex flex-col gap-2 text-[16px] leading-[1.375rem] tracking-[-0.24px] text-black-90 opacity-70 "
				dangerouslySetInnerHTML={{
					__html: renderRichText(blok?.list),
				}}
			/>
		</div>
	)
}
export default UnorderedList
