import { Link } from '@remix-run/react'
import { type ImageLinkStoryblok } from '#types/component-types-sb'

interface ImageLinkProps {
	blok: ImageLinkStoryblok
}

const ImageLink = ({ blok }: ImageLinkProps) => {
	return (
		<Link to={blok.link}>
			<img src={blok.image?.filename} alt="ads"></img>
		</Link>
	)
}

export default ImageLink
