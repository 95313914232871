import { storyblokEditable } from '@storyblok/react'
import { Spacer } from '#app/components/spacer'
import { type SmSpacerStoryblok } from '#types/component-types-sb'

const smSpacer = ({ blok }: SmSpacerStoryblok) => {
	// Default to true if 'spacing8px' is not defined on the blok
	const isOn = blok.spacingSm !== undefined ? blok.spacingSm : true

	// Pass '5xs' to always use the 8px size option when the spacer is "on"
	return (
		<Spacer
			{...storyblokEditable(blok)}
			key={blok._uid}
			size="sm"
			isOn={isOn}
		/>
	)
}

export default smSpacer
