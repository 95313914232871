import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useUtmParams } from '#app/hooks/useUtmParams'
import { trackBrandView } from '#app/utils/tracking/amplitude/brand-view'
import { type BrandsBrandCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import PlentyBody from '../typography/plenty-body'

const BrandsBrandCard = ({ blok }: BrandsBrandCardStoryblok) => {
	const utmParams = useUtmParams()

	const amplitudeTracking = () => {
		trackBrandView({ redirectTo: blok.redirectTo }, utmParams)
	}
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex flex-col items-center"
		>
			<Link
				to={blok.redirectTo}
				className="group z-10"
				onClick={amplitudeTracking}
			>
				<div
					{...storyblokEditable(blok)}
					key={blok._uid}
					className="relative w-full"
				>
					<img
						src={blok.brandImage?.filename}
						alt="brand"
						className="h-auto w-full rounded-2xl object-contain"
					/>
					<BlackBackgroundColorForImages className=" rounded-2xl" />
					<div className="absolute inset-0 flex items-center justify-center rounded-xl transition-all group-hover:scale-110 ">
						<img src={blok.brandLabelImage?.filename} alt="brand" />
					</div>{' '}
				</div>
				<div className="flex w-full items-start justify-start pt-4">
					<PlentyBody size="md">{blok.brandName}</PlentyBody>
				</div>
			</Link>
		</div>
	)
}

export default BrandsBrandCard
