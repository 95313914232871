import { type ImageBlokStoryblok } from '#types/component-types-sb'

const ImageBlok = ({ blok }: ImageBlokStoryblok) => {
	const imageUrl = blok.image?.filename || '/default-fallback-image.png' // Replace with your default image path if needed
	const altText = blok.image?.alt || 'Image description' // Provide a descriptive alt text for accessibility
	return (
		<div>
			<img src={imageUrl} alt={altText} className=" w-full object-contain" />
		</div>
	)
}

export default ImageBlok
