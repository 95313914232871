import { useLoaderData } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { motion, useAnimation } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientOnly } from 'remix-utils/client-only'
import { type loader } from '#app/routes/_public+/$'
import {
	type StoreInformationStoryblok,
	type StoresShuffleStoryblok,
} from '#types/component-types-sb'
import { type ClerkProduct } from '#types/product'
import { ProductCarousel } from '../product-carousel'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'
import {
	Carousel,
	type CarouselApi,
	CarouselContent,
	CarouselItem,
} from '../ui/carousel'
import PlentyBodyLink from '../ui/plenty-body-link'

const carouselAnimations = {
	hidden: {
		opacity: 0,
		innerHeight: 0,
		transition: {
			duration: 0,
			delay: 0,
		},
	},
	visible: {
		innerHeight: '100%',
		opacity: 1,
		transition: {
			duration: 0.5,
			delay: 0.5,
		},
	},
}

const carouselContainer = {
	hidden: {
		height: 0,
		transition: {
			duration: 0.4,
			delay: 0,
		},
	},
	visible: {
		height: 'fit-content',
		transition: {
			duration: 0.5,
			delay: 0,
		},
	},
}

const StoreShuffleV2 = ({ blok }: { blok: StoresShuffleStoryblok }) => {
	const [api, setApi] = React.useState<CarouselApi>()
	const [clerkProducts, setClerkProducts] = useState<
		ClerkProduct[] | undefined
	>(undefined)
	const { storesInformation } = useLoaderData<typeof loader>()
	const { t } = useTranslation('common')
	const stores = useMemo(() => {
		return storesInformation.filter(
			(store: { content: StoreInformationStoryblok }) =>
				store?.content.gender_store?.some(gender =>
					blok.filterByGender.includes(gender),
				),
		)
	}, [blok.filterByGender, storesInformation])
	const [selectedStore, setSelectedStore] = useState<number>(
		Math.floor(Math.random() * stores.length),
	)

	const controls = useAnimation()
	useEffect(() => {
		if (!api) return
		api.containerNode().addEventListener('mousedown', event => {
			event.stopPropagation()
		})
		api.containerNode().addEventListener('touchstart', event => {
			event.stopPropagation()
		})
		api.scrollTo(selectedStore)
	}, [api, selectedStore])

	useEffect(() => {
		let isMounted = true // Add a flag to track if the component is mounted

		const endpoint = 'popular'
		const storeFilter = `store=${stores[selectedStore].slug}`
		const genderFilter = blok.filterByGender
			? blok.filterByGender.map((cat: string) => `categoryKey=${cat}`).join('&')
			: ''

		const url = `/resources/productCarouselDataClerk?endpoint=${encodeURIComponent(
			endpoint,
		)}&${storeFilter}&${genderFilter}`

		const fetchData = async () => {
			try {
				const response = await fetch(url)
				if (!response.ok) {
					throw new Error(`Network response was not ok: ${response.statusText}`)
				}
				const data = await response.json()
				setClerkProducts(data)
				setTimeout(() => {
					if (isMounted) {
						controls.start('visible')
					}
				}, 650)
			} catch (error) {
				console.error('Failed to fetch products:', error)
				// Delay the update of setIsShuffling to false after 0.8 seconds
				setTimeout(() => {
					if (isMounted) {
						controls.start('visible')
					}
				}, 650)
				setClerkProducts([])
			}
		}
		fetchData()

		return () => {
			isMounted = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStore])

	const handleStoreChange = () => {
		setClerkProducts([])

		const randomStore = Math.floor(Math.random() * stores.length)
		if (randomStore === selectedStore) {
			handleStoreChange()
			return
		}

		controls.start('hidden')
		setTimeout(() => {
			setSelectedStore(randomStore)
		}, 400)
	}

	return (
		<ClientOnly fallback={<></>}>
			{() => (
				<motion.div
					className="bg-black text-white"
					animate={controls}
					{...storyblokEditable(blok)}
				>
					<Carousel
						setApi={setApi}
						opts={{
							duration: 35,

							skipSnaps: true,
							containScroll: 'trimSnaps',
						}}
					>
						<CarouselContent>
							{stores.map((store: any, index: number) => (
								<CarouselItem key={index} className="">
									<div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 md:gap-12 md:pr-[var(--plenty-padding)]">
										<div className="flex flex-col space-y-4 transition-all">
											<div className="flex flex-col transition-all">
												<PlentyHeading as="h7" className="text-white">
													{t('store_shuffle_text')} {store.content.store_name}
												</PlentyHeading>
												<PlentyBody className="h-12 overflow-hidden text-white md:h-fit">
													{store.content.store_shuffle_description
														? store.content.store_shuffle_description
														: store.content.store_description}
												</PlentyBody>
											</div>
											<div className="flex flex-row space-x-4 ">
												<PlentyBodyLink
													link={`/stores/${stores[index].slug}`}
													barClassName="bg-white opacity-20 rounded-full group-hover:opacity-30"
												>
													{blok.linkStoreText}
												</PlentyBodyLink>
												<PlentyBodyLink
													barClassName="bg-white opacity-20 rounded-full group-hover:opacity-30"
													customIcon="shuffle"
													className="cursor-pointer"
													onClick={handleStoreChange}
												>
													{blok.shuffleText}
												</PlentyBodyLink>
											</div>
										</div>
										<div className="max-h-[190px] md:max-h-[220px] ">
											<img
												src={
													stores[index].content?.store_suffle_image?.filename ||
													stores[index].content.store_image.filename ||
													'https://images.unsplash.com/photo-1441986300917-64674bd600d8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
												}
												alt="something"
												className="h-full w-full rounded-md object-cover"
											/>
										</div>
									</div>
									<motion.div variants={carouselContainer}>
										<motion.div
											className="bg-blue flex max-h-[380px] flex-col space-y-8 transition-all sm:max-h-[450px] md:max-h-none"
											variants={carouselAnimations}
										>
											{clerkProducts?.length && selectedStore === index ? (
												<ProductCarousel
													clerkProducts={clerkProducts}
													title={blok.title}
													subtitle=""
													storeParams={blok.store}
													linkText={blok.linkText}
													linkUrl={blok.linkUrl}
													smallVersion
												/>
											) : (
												<div className="flex h-[522px] flex-col" />
											)}
										</motion.div>
									</motion.div>
								</CarouselItem>
							))}
						</CarouselContent>
					</Carousel>
				</motion.div>
			)}
		</ClientOnly>
	)
}

export default StoreShuffleV2
