import { type Params, useMatches } from '@remix-run/react'
import { useEffect, useState } from 'react'

interface Match {
	id: string
	pathname: string
	params: Params<string>
	data: { user: any }
	handle: any
}

const useAuthenticatedUser = () => {
	const [user, setUser] = useState<null | any>(null)
	const matches = useMatches()

	useEffect(() => {
		const rootLoader = matches.find(match => match.id === 'root') as Match
		if (!rootLoader) return
		setUser(rootLoader?.data?.user || null)
	}, [matches])
	return user
}

export default useAuthenticatedUser
