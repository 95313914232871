import { Link } from '@remix-run/react'
import { useMemo } from 'react'
import { cn } from '#app/utils/misc'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'
import { Icon, type IconName } from './icon'

interface PlentyBodyLinkProps extends React.HTMLProps<HTMLDivElement> {
	children: React.ReactNode
	link?: string
	barClassName?: string
	iconClassName?: string
	customIcon?: IconName
	fullWidth?: boolean
}

const PlentyBodyLink = ({
	children,
	link,
	className,
	barClassName,
	iconClassName,
	customIcon,
	fullWidth,
	...rest
}: PlentyBodyLinkProps) => {
	const content = useMemo(() => {
		return (
			<div
				className={cn(
					'group relative flex items-center justify-between',
					fullWidth ? 'w-full justify-between' : 'w-fit',
					className,
				)}
				{...rest}
			>
				<PlentyButtonsAndLinks as="btn-sm" className="relative z-10">
					{children}
				</PlentyButtonsAndLinks>
				<Icon
					className={cn(
						'z-10 ml-2 h-4 w-4 transform transition-transform duration-300 group-hover:translate-x-1',
						iconClassName,
					)}
					name={customIcon ? customIcon : 'right-arrow'}
				/>
				<div
					className={cn(
						'absolute bottom-[2px] left-0 z-0 h-[7px] w-full rounded-full bg-beige-70 transition-colors duration-300 group-hover:bg-beige-90',
						barClassName,
					)}
				/>
			</div>
		)
	}, [
		barClassName,
		children,
		className,
		customIcon,
		fullWidth,
		iconClassName,
		rest,
	])

	return <>{link ? <Link to={link}>{content}</Link> : <div> {content}</div>}</>
}

export default PlentyBodyLink
