import { storyblokEditable } from '@storyblok/react'
import { type PerkWithIconStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const PerkWithIcon = ({ blok }: PerkWithIconStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="fle
			
			x flex-col items-center gap-2"
		>
			<img src={blok.icon.filename} alt="icon" />
			<PlentyHeading as="h7" className=" text-white">
				{blok.headingText}
			</PlentyHeading>
			<PlentyBody size="md" className="text-white">
				{blok.bodyText}
			</PlentyBody>
		</div>
	)
}

export default PerkWithIcon
