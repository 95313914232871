import { useEffect, useRef } from 'react';

const UseCookieScript = () => {
  const injected = useRef(false);

  useEffect(() => {
    // Check if the script is already injected to prevent duplicates
    if (!injected.current) {
      const script = document.createElement('script');
      script.id = "CookieConsent";
      script.src = "https://policy.app.cookieinformation.com/uc.js";
      script.setAttribute("data-culture", "DA");
      script.setAttribute("data-gcm-version", "2.0");
      script.type = "text/javascript";
      
      // Append the script to the document head
      document.head.appendChild(script);

      // Update the ref to indicate the script has been injected
      injected.current = true;

      // Optional: Cleanup function to remove the script when the component unmounts
      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }
  }, []); // Empty array means this effect runs once on mount

  return null;
};

export default UseCookieScript;
