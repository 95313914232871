import React, { useEffect, useState } from 'react'
import { cn } from '#app/utils/misc'
import PlentyBody from '../typography/plenty-body'

export const SlidingText = ({
	text,
	direction = 'right',
	className,
}: {
	text: string
	direction?: 'left' | 'right'
	className?: string
}) => {
	const containerRef = React.useRef<HTMLDivElement>(null)
	const scrollerRef = React.useRef<HTMLUListElement>(null)

	useEffect(() => {
		function addAnimation() {
			if (containerRef.current && scrollerRef.current) {
				const scrollerContent = Array.from(scrollerRef.current.children)

				scrollerContent.forEach(item => {
					const duplicatedItem = item.cloneNode(true)
					if (scrollerRef.current) {
						scrollerRef.current.appendChild(duplicatedItem)
					}
				})

				getDirection()
				getSpeed()
				setStart(true)
			}
		}
		addAnimation()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const [start, setStart] = useState(false)

	const getDirection = () => {
		if (containerRef.current) {
			if (direction === 'left') {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'forwards',
				)
			} else {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'reverse',
				)
			}
		}
	}
	const getSpeed = () => {
		if (containerRef.current) {
			containerRef.current.style.setProperty('--animation-duration', '200s')
		}
	}
	return (
		<div
			ref={containerRef}
			className={cn(
				'scroller relative z-20  overflow-hidden bg-black-90 ',
				className,
			)}
		>
			<ul
				ref={scrollerRef}
				className={cn(
					'flex w-max min-w-full shrink-0 flex-nowrap gap-4 py-2',
					start && 'animate-scroll ',
				)}
			>
				{Array.from({ length: 20 }).map((_, i) => (
					<li className="flex space-x-2" key={`${direction}-${i}`}>
						<PlentyBody className="text-white">{text}</PlentyBody>
						<PlentyBody className="text-mint-green-80p"> / </PlentyBody>
					</li>
				))}
			</ul>
		</div>
	)
}
